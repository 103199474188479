var slick = require('../vendor/slick.min.js');
// require('jquery-ui');
require('webpack-jquery-ui/datepicker');
// var Slider = require('../../js/sliders.js');
// var AOS = require('aos');
import {
    CountUp
} from 'countup.js';

import Utilities from './utilities/utilities';

const utility = new Utilities;

$(document).ready(function () {
    // Anchor link scrollTop animation
    //

    $.datepicker.regional['hr'] = {
  closeText: 'Zatvori',
  // prevText: '&#x3c;',
  // nextText: '&#x3e;',
  currentText: 'Danas',
  monthNames: ['Siječanj', 'Veljača', 'Ožujak', 'Travanj', 'Svibanj', 'Lipanj',
    'Srpanj', 'Kolovoz', 'Rujan', 'Listopad', 'Studeni', 'Prosinac'
  ],
  monthNamesShort: ['Siječanj', 'Veljača', 'Ožujak', 'Travanj', 'Svibanj', 'Lipanj',
    'Srpanj', 'Kolovoz', 'Rujan', 'Listopad', 'Studeni', 'Prosinac'
  ],
  dayNames: ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'],
  dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'],
  dayNamesMin: ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'],
  weekHeader: 'Tje',
  dateFormat: 'dd.mm.yy.',
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ''
};
$.datepicker.regional['en'] = {
  closeText: 'Close',
  // prevText: '&#x3c;',
  // nextText: '&#x3e;',
  currentText: 'Today',
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  weekHeader: 'Week',
  dateFormat: 'dd.mm.yy.',
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ''
};
$.datepicker.regional['de'] = {
  closeText: "Schließen",
  // prevText: "&#x3C;Zurück",
  // nextText: "Vor&#x3E;",
  currentText: "Heute",
  monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni",
    "Juli", "August", "September", "Oktober", "November", "Dezember"
  ],
  monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun",
    "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"
  ],
  dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
  dayNamesShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  weekHeader: "KW",
  dateFormat: "dd.mm.yy",
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ""
};
$.datepicker.regional['ba'] = {
  closeText: "Zatvori",
  // prevText: "&#x3C;",
  // nextText: "&#x3E;",
  currentText: "Danas",
  monthNames: ["Januar", "Februar", "Mart", "April", "Maj", "Juni",
    "Juli", "August", "Septembar", "Oktobar", "Novembar", "Decembar"
  ],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun",
    "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"
  ],
  dayNames: ["Nedelja", "Ponedeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"],
  dayNamesShort: ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"],
  dayNamesMin: ["Ne", "Po", "Ut", "Sr", "Če", "Pe", "Su"],
  weekHeader: "Wk",
  dateFormat: "dd.mm.yy",
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ""
};
$.datepicker.regional['it'] = {
  closeText: "Chiudi",
  // prevText: "&#x3C;Prec",
  // nextText: "Succ&#x3E;",
  currentText: "Oggi",
  monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
    "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
  ],
  monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu",
    "Lug", "Ago", "Set", "Ott", "Nov", "Dic"
  ],
  dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
  dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
  weekHeader: "Sm",
  dateFormat: "dd/mm/yy",
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ""
};
$.datepicker.regional['cz'] = {
  closeText: "Zavřít",
  // prevText: "&#x3C;Dříve",
  // nextText: "Později&#x3E;",
  currentText: "Nyní",
  monthNames: ["leden", "únor", "březen", "duben", "květen", "červen",
    "červenec", "srpen", "září", "říjen", "listopad", "prosinec"
  ],
  monthNamesShort: ["led", "úno", "bře", "dub", "kvě", "čer",
    "čvc", "srp", "zář", "říj", "lis", "pro"
  ],
  dayNames: ["neděle", "pondělí", "úterý", "středa", "čtvrtek", "pátek", "sobota"],
  dayNamesShort: ["ne", "po", "út", "st", "čt", "pá", "so"],
  dayNamesMin: ["ne", "po", "út", "st", "čt", "pá", "so"],
  weekHeader: "Týd",
  dateFormat: "dd.mm.yy",
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ""
};
$.datepicker.regional['fr'] = {
  closeText: "Fermer",
  // prevText: "Précédent",
  // nextText: "Suivant",
  currentText: "Aujourd'hui",
  monthNames: ["janvier", "février", "mars", "avril", "mai", "juin",
    "juillet", "août", "septembre", "octobre", "novembre", "décembre"
  ],
  monthNamesShort: ["janv.", "févr.", "mars", "avr.", "mai", "juin",
    "juil.", "août", "sept.", "oct.", "nov.", "déc."
  ],
  dayNames: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
  dayNamesShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
  dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
  weekHeader: "Sem.",
  dateFormat: "dd/mm/yy",
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ""
};
$.datepicker.regional['sk'] = {
  closeText: "Zavrieť",
  // prevText: "&#x3C;Predchádzajúci",
  // nextText: "Nasledujúci&#x3E;",
  currentText: "Dnes",
  monthNames: ["január", "február", "marec", "apríl", "máj", "jún",
    "júl", "august", "september", "október", "november", "december"
  ],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Máj", "Jún",
    "Júl", "Aug", "Sep", "Okt", "Nov", "Dec"
  ],
  dayNames: ["nedeľa", "pondelok", "utorok", "streda", "štvrtok", "piatok", "sobota"],
  dayNamesShort: ["Ned", "Pon", "Uto", "Str", "Štv", "Pia", "Sob"],
  dayNamesMin: ["Ne", "Po", "Ut", "St", "Št", "Pia", "So"],
  weekHeader: "Ty",
  dateFormat: "dd.mm.yy",
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ""
};
$.datepicker.regional['sl'] = {
  closeText: "Zapri",
  // prevText: "&#x3C;Prejšnji",
  // nextText: "Naslednji&#x3E;",
  currentText: "Trenutni",
  monthNames: ["Januar", "Februar", "Marec", "April", "Maj", "Junij",
    "Julij", "Avgust", "September", "Oktober", "November", "December"
  ],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun",
    "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"
  ],
  dayNames: ["Nedelja", "Ponedeljek", "Torek", "Sreda", "Četrtek", "Petek", "Sobota"],
  dayNamesShort: ["Ned", "Pon", "Tor", "Sre", "Čet", "Pet", "Sob"],
  dayNamesMin: ["Ne", "Po", "To", "Sr", "Če", "Pe", "So"],
  weekHeader: "Teden",
  dateFormat: "dd.mm.yy",
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ""
};
$.datepicker.regional['rs'] = {
  closeText: "Zatvori",
  prevText: "&#x3C;",
  nextText: "&#x3E;",
  currentText: "Danas",
  monthNames: ["Januar", "Februar", "Mart", "April", "Maj", "Jun",
    "Jul", "Avgust", "Septembar", "Oktobar", "Novembar", "Decembar"
  ],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun",
    "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"
  ],
  dayNames: ["Nedelja", "Ponedeljak", "Utorak", "Sreda", "Četvrtak", "Petak", "Subota"],
  dayNamesShort: ["Ned", "Pon", "Uto", "Sre", "Čet", "Pet", "Sub"],
  dayNamesMin: ["Ne", "Po", "Ut", "Sr", "Če", "Pe", "Su"],
  weekHeader: "Sed",
  dateFormat: "dd.mm.yy",
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ""
};
$.datepicker.setDefaults($.datepicker.regional[$('body').data('lang')]);

    if($('[data-srcset="background"]').length){
        utility.bgSrcset($('[data-srcset="background"]'));
    }

    $(document).on('click', '.anchor', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var id = $(this).attr('href');
        if ($(id).length) {
            $('html, body').animate({
                scrollTop: $(id).offset().top - 90
            });
        }
    });

    const bktt = $('#back_to_top');
    bktt.on('click', function (e) {
        e.preventDefault();
        utility.scrollToTop();
    });

    //
    // Navigation animation
    //
    var menu = $(document).find('[data-menu]').attr('data-menu');
    $(document).on('click', '[data-menu]', function (e) {
        e.preventDefault();
        $(menu).toggleClass('open');
        $(menu).find('.main-navigation').toggleClass('show');
        $('.nav-backdrop').toggleClass('show');
        $('body').toggleClass('menu-is-open');
    });

    //
    // Cookie set and link action
    //
    var cookie_name = 'seenCookieMessage';
    //CookieMonster.eraseCookie(cookie_name);
    /*$(document).on('click', '#btnCookie', function(e)  {
        e.preventDefault();
        e.stopPropagation();
        CookieMonster.setCookie(cookie_name, 'yes', 60);
        $('.cookie-component').addClass('close-cookie');
    });*/

    //
    // Close popups(...) when clicked outside of target element
    //

    // Definiranje visine contenta
    utility.setEqualHeight('.carousel-item-title');
    utility.setEqualHeight('.carousel-item-desc');
    utility.setEqualHeight('.carousel-item-link');
    utility.setEqualHeight('.grid-item-title');
    utility.setEqualHeight('.grid-item-text');
    utility.setEqualHeight('.blog-title');
    utility.setEqualHeight('.blog-img-wrapper');
    utility.setEqualHeight('.blog-desc');
    // Kraj Definiranja visine contenta

    // CATEGORY  TAB SWITCHER
    $('.cat-btn').on('click', function (e) {
        e.preventDefault();
        var thisClass = $(this).attr('id');
        $('.all-btn').removeClass('active');
        $('.cat-btn').removeClass('active');
        $(this).addClass('active');
        $('.cat-sort').each(function () {
            if ($($(this)).hasClass(thisClass)) {
                $(this).show(400);
            } else {
                $(this).hide(400);
            }
        })
    });

    $('.all-btn').on('click', function (e) {
        e.preventDefault();
        $('.cat-btn').removeClass('active');
        $(this).addClass('active');
        $('.cat-sort').each(function () {
            $(this).show(400);
        })

    });

    var fullUrl = window.location.href;
    var referenceHash = window.location.hash;
    var referenceId = referenceHash.slice(1);
    $('.cat-btn').each(function () {
        if ($(this).attr('id') === referenceId) {
            $(this).trigger('click');
        }
    })



    if ($('.header-slider-item').length) {
        $('.header-slider-item').each(function (el) {
            if ($(this).find('.video').length) {
                var _this = $(this).find('.video')[0];
                var videoSrc = _this.getAttribute('data-src');

                _this.setAttribute('src', videoSrc)
            }
        })
    }
});


$(window).on('scroll', function () {
    if ($('.dm_counter_slider').length) {
        var eT = $('.dm_counter_slider').offset().top,
            eH = $('.dm_counter_slider').outerHeight(),
            wH = $(window).height(),
            wS = $(this).scrollTop();
        if (wS > (eT + eH - wH)) {
            $(window).off('scroll');

            // animateCounter();
            countNumber();
        }
    }

})
// YouTube custom play button
// Fades out cover image and button on click and starts video that is behind button and/or image
$(document).on('click', '#video-play-lazy', function (e) {
    e.preventDefault();
    var _this = $(this);
    $(this).fadeOut('normal');
    $(this).parent().find('img').fadeOut('normal');
    var iframe = document.createElement('iframe');
    var embed = $(this).parent().data('embed');
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "");
    iframe.setAttribute("src", "https://www.youtube.com/embed/" + embed + "?enablejsapi=1&version=3&playerapiid=ytplayer");
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    $(this).parent().append(iframe);
    setTimeout(function () {
        _this.parent().find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
    }, 500);
});
$(document).on('click', '#video-play-normal', function (e) {
    e.preventDefault();
    var _this = $(this);
    $(this).fadeOut('normal');
    $(this).parent().find('img').fadeOut('normal');
    $(this).parent().find('iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');

});

// pause video on click
$(document).on('click', '.navi', function (e) {
    e.preventDefault();
    for (var i = 0; i < $(this).parent().find('iframe').length; i++) {
        $(this).parent().find('iframe')[i].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
    }
});


$(function () {
    $('.nav-item').each(function () {
        var windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var navItemWidth = parseInt($('.nav-item').css('width'));
        var navItemPLeft = parseInt($('.nav-item').css('padding-left'));
        var navItemPRight = parseInt($('.nav-item').css('padding-right'));
        var navItemPadding = navItemPLeft + navItemPRight;
        $('.nav-link').each(function () {

            var navLinkWidth = parseInt($(this).css('width'));
            var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 15);
            $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            if (windowWidth < 370) {
                var navLinkWidth = parseInt($(this).css('width'));
                var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 20);
                $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            }
            if (windowWidth < 290) {
                var navLinkWidth = parseInt($(this).css('width'));
                var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 25);
                $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            }
            if (windowWidth < 237) {
                var navLinkWidth = parseInt($(this).css('width'));
                var remainingWidth = navItemWidth - (navItemPadding + navLinkWidth + 30);
                $(this).parent().find($('.nav_dropdown_toggler')).css('width', remainingWidth);
            }

        })
    });
})

function animateCounter() {
    function commaSeparateNumber(val) {
        while (/(\d+)(\d{3})/.test(val.toString())) {
            val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
        }
        return val;
    }

    $('.count').each(function () {
        $(this).prop('Counter', 0).animate({
            Counter: $(this).text()
        }, {
            duration: 8000,
            easing: 'swing',
            step: function (now) {
                $(this).text(commaSeparateNumber(Math.ceil(now)));
            }
        });
    });

}

function countNumber() {
    const optionsCounter = {
        duration: 3,
        separator: '.',
    };
    $('.count[data-count]').each(function () {
        var countTo = Number($(this).data('count'));

        let demo = new CountUp($(this).attr('id'), countTo, optionsCounter);
        if (!demo.error) {
            demo.start();
        } else {
            console.error(demo.error);
        }
    })
}

utility.navbarScroll();
utility.defineBackToTop();

let SimplePopup = require('../vendor/simplepopup/simplePopup.js');
$(function () {
    $('.popup-trigger').on('click', function(e) {
        e.preventDefault();
        new SimplePopup({
            'contentClass': 'popup-content',
            'content': false,
            'contentHtmlId': 'popup-content',
            'overlayClass': 'popup-overlay-',
            'contentCss': {
                'width': '50%',
                'background': '#0d0d0d',
                'border-color': '#83b817',
            }
        });
    });
});
$(function () {
    $('.simplePopupClose').on('click', function(e) {
        document.getElementById( 'banner-popup' ).style.display = 'none';
    });
});