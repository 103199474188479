import {
    reservationFormSetup,
    reservationDropdown,
    reservationNewWindow
} from './reservation_dropdown_form/reservation_dropdown_form';

class Modules {
    constructor() {
    }

    onDocumentReady() {
        if ($('#contact_subject').length) {
            import('./contact_us_block/contact_us_block').then(({
                default: contactBlock
            }) => {
                contactBlock();
            })
        }

        if ($('#reservation-dropdown').length) {
            reservationFormSetup();
            reservationNewWindow();
        }
    }

    onDocumentClick() {
        reservationDropdown();
    }
}

export default Modules;