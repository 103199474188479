const socialShareInner = (el) => {
  // let tarEl = $(el).parent().find('.social-icon-popup');
  // tarEl.toggleClass('active');
  // if (tarEl.hasClass('on')) {
  //   tarEl.removeClass('on');
  // } else {
  //   setTimeout(function () {
  //     tarEl.addClass('on');
  //   }, 100)
  // }
  if($('.at-expanding-share-button-mobile').length){
    $('.at-expanding-share-button-toggle-bg').on('click.shareMobile', function(event){
     
      setTimeout(() => {
        console.log('should be clicked second time');
        $('.at-expanding-share-button-toggle-bg').trigger('click');
        $('.at-expanding-share-button-mobile-overlay').trigger('click');
        $('.at-expanding-share-button-toggle-bg').off('click.shareMobile');
      }, 500);  
    })
    $('.at-expanding-share-button-toggle-bg').trigger('click.shareMobile');
  } else {
    $('.at-expanding-share-button-toggle-bg').trigger('click');
  }
 
    
}

export default socialShareInner;