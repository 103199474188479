class Utilities {
  constructor() {}
  definePatternHeight() {
    var height = document.body.clientHeight;
    var bodyHeight = height;
    $('.pattern-overlay').css('height', bodyHeight);
  }
  setEqualHeight(element) {
    var maxContentHeight = 0;
    $(element).each(function () {
      if ($(this).height() > maxContentHeight) {
        maxContentHeight = $(this).height();
      }
    });
    $(element).height(maxContentHeight);
  }
  navbarScroll() {

    $(function () {
      var $nav = $(".header-container");
      var $header = $('.header-container');
      var $body = $('body');
      $(document).scroll(function () {
        $nav.toggleClass('scrolled', $(this).scrollTop() > $header.height());
        if ($nav.hasClass('scrolled')) {
          $('.header-nav-top').hide(300);
        } else {
          $('.header-nav-top').show(300);
        }
      });

      $body.css('margin-top', $header.height());
      if ($('.header-container').offset().top > $('.header-container').height()) {
        $('.header-container').addClass('scrolled');
      }
    });
  }
  scrollToTop() {
    $('html, body').animate({
      scrollTop: 0
    }, 800);
  }

  defineBackToTop() {
    $(document).on('scroll', function () {

      // back to top button on scroll
      var s = $(window).scrollTop(),
        d = $(document).height(),
        c = $(window).height();

      var scrollPercent = Math.trunc((s / (d - c)) * 100);
      // If Scroll is > 50 show back_to_top btn, 
      if (scrollPercent >= 50) {
        setTimeout(function () {
          $('#back_to_top').removeClass('animate-out');
          $('#back_to_top').addClass('animate-in');
        }, 500)
      }
      // if scroll is < 10 hide back_to_top btn
      if (scrollPercent <= 10) {
        setTimeout(function () {
          $('#back_to_top').removeClass('animate-in');
          $('#back_to_top').addClass('animate-out');
        }, 500)
      }
    });
  }
  bgSrcset(imgEl) {
    $.each(imgEl, function (index, el) {
      $.each($(el).data(), function (i, e) {
        if (i.split('-')[0] == 'img') {
          let breakpoint = i.split('-')[1];
          // console.log(breakpoint, $(window).width())
          if ($(window).width() < breakpoint) {
            $(el).css('background-image', 'url(' + $(el).data(i) + ')');
            // console.log($(el).css('background-image'), e, $(el).data(i))
          }
        }
      })
    })

  }
}

export default Utilities;