export const isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();
  if ($('#homepage').length) {
    viewportBottom += 1400;
  }
  // console.log('hello from viewport');
  return elementBottom > viewportTop && elementTop < viewportBottom;
};


export const addThisInViewport = function () {
  const ADD_THIS_API_KEY = process.env.ADD_THIS_API_KEY;

  let executed = false;
  return function () {
    if (!executed) {
      // console.log('exec');
      executed = true;
      $('footer').after('<script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=' + ADD_THIS_API_KEY + '" async="async"></script>');
    }
  };
}();