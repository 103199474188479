export function reservationFormSetup(){
        $(".reservation-form-wrapper.desktop #check_in_date_desktop").datepicker();
        $(".reservation-form-wrapper.mobile #check_in_date_mobile").datepicker();
        // const mobileForm = ' <div id="reservation-dropdown" class="reservation-form-wrapper">' + $(".reservation-container.mobile").children(".reservation-form-wrapper").html() + '</div>';
        // const desktopForm = ' <div id="reservation-dropdown" class="reservation-form-wrapper">' + $(".reservation-container.desktop").children(".reservation-form-wrapper").html() + '</div>';
        
        // if($(window).width () < 1200){
        //         $('.reservation-form-wrapper.desktop').remove();
        // } else{
        //         $('.reservation-form-wrapper.mobile').remove();
        // }
        // $(window).on('resize', function(){
        //         if($(window).width () < 1200){
        //                 $('.reservation-form-wrapper.desktop').remove();
        //         } else{
        //                 $('.reservation-form-wrapper.mobile').remove();
        //         }       
        // })
        // $("#check_out_date").datepicker();
        // $("#check_in_date_mobile").datepicker();
        // $("#check_out_date_mobile").datepicker();
}

export function reservationDropdown() {
    $(document).on('click', '[data-dropdown="reservation"]', function (e) {
        e.preventDefault();
        $(this).parent().find('#reservation-dropdown').toggle();
        $(this).toggleClass('open');
    });
}

export function reservationNewWindow() {
    $(document).on('click', 'form#dropdown-reserve-form button[type="submit"], #reservation-cancel', function (e) {
        e.preventDefault();
        e.stopPropagation();

        let url = $(this).parents('form').attr('action');
        if ($(this).attr("id") == "reservation-cancel") {
            url = $(this).attr('href');
        }

        let data = $(this).parents('form').serialize();

        $.post(url, data, function (response) {
            window.open(response.data);
        }, 'json');
    });

    $(document).on('click', 'body.specialOffersDetail a.reservation-btn, body.specialOffersGroup a.reservation-btn, a.reservation-btn', function (e) {
        e.preventDefault();
        e.stopPropagation();
        console.log('reserve btn click');
        $.post($(this).attr('href'), function (response) {
            window.open(response.data);
        }, 'json')
    });
}