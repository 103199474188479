import Components from './components/components';
import Modules from './modules/modules';
import polyfill from './_config/js/polyfill';
import {
  isInViewport,
  addThisInViewport
} from './utilities/viewport/viewport';
const components = new Components;
const modules = new Modules;

$.fn.isInViewport = isInViewport;


$(function () {
  polyfill();
  components.onDocumentReady();
  modules.onDocumentReady();
});

$(window).on('scroll', components.onWindowScroll);
$(window).on('load scroll resize', function () {
  if ($('footer').isInViewport()) {
    addThisInViewport();
  }
  if($('.share-widget-inner').length){
    if ($('.share-widget-inner').isInViewport()) {
      addThisInViewport();
    }
  }
  
});
components.onDocumentClick();
modules.onDocumentClick();

import FeatureWidget from './FeatureWidget/FeatureWidget';
// $(function () {
//   // zakomentirati da se isključi feature widget
//   const featureWidget = new FeatureWidget();
//   featureWidget.init();
// })